
import { isMobile } from '~/helpers/mobile/DeviceType'

export default {
  name: 'AtomRoute',
  props: {
    markerId: {
      required: false,
      type: String,
      default: ''
    },
    mode: {
      required: false,
      type: String,
      default: ''
    },
    order: {
      required: false,
      type: Number,
      default: 0
    },
    displayAllTabOrder: {
      required: false,
      type: Number,
      default: 0
    },
    name: {
      required: false,
      type: String,
      default: ''
    },
    time: {
      required: false,
      type: String,
      default: '0s'
    },
    distance: {
      required: false,
      type: String,
      default: '0km'
    },
    index: {
      required: false,
      type: Number,
      default: 1
    },
  },
  computed: {
    orderDisplay() {
      if (this.transportationType === "display-all" && this.displayAllTabOrder !== 0) {
        return this.displayAllTabOrder.toString().padStart(2, '0')
      }
      return this.order.toString().padStart(2, '0')
    },
    transportationType() {
      return this.$parent.filters.transportationType
    },
    showItems() {
      if (this.transportationType === "display-all") {
        return true;
      }
      return this.transportationType === this.mode;
    }
  },
  methods: {
    drawRouteOnItemClicked() {
      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem
        })
      }
      const target = this.$el
      target.classList.toggle('atom-route-active')
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove('atom-route-active')
      })

      this.$parent.$parent.$parent.$refs.map.calcRoute(this.markerId, this.mode)
      if (isMobile()) {
        this.$parent.$parent.$parent.toggleSidebar()
      }
    }
  }
}
