
import ClickOutside from 'vue-click-outside'
export default {
  name: 'AtomSpaceDropdown',
  directives: {
    ClickOutside
  },
  props: {
    options: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    disclaimer: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isMenuOpen: false,
      selected: 'none'
    }
  },
  computed: {
    defaultFitoutActive() {
      return this.$store.getters.getActiveProjectSettings.floor.activeFitout
    },
    currentSelection() {
      if (this.selected) {
        return this.$props.options.find((item) => item.value === this.selected)
      } else {
        return {}
      }
    },
    projectSelectorOptions() {
      return this.$store.state.base.meta.generalConfig.projectSelector
    }
  },
  watch: {
    options(newVal) {
      this.$nextTick(() => {
        if (this.defaultFitoutActive && newVal && newVal.length > 2) {
          this.handleOptionSelect(newVal.find((item) => item.value === newVal[2].value))
        }
      })
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    handleClickOutside() {
      this.isMenuOpen = false
    },
    handleOptionSelect(option) {
      this.isMenuOpen = false
      this.selected = option.value
      this.$emit('change', option.value)
    },
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    }
  }
}
