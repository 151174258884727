
export default {
  name: 'AtomListCircle',
  props: {
    title: {
      required: true,
      type: String,
      default: ''
    },
    text: {
      required: true,
      type: String,
      default: ''
    }
  }
}
